.upper-menu{
    width: calc(100% - 12px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 6px;
    height: 48px;
    margin-top: 7px;
}

.upper-menu-item{
    flex: 1;
    margin-right: 9px;
    border-radius: 12px 12px 12px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Muller', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF
}

.upper-menu-item:last-child {
    margin-right: 0; /* Убираем отступ у последнего элемента */
}

.upper-menu-item-title{
    margin-top: 2px;
}
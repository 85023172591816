.searchbar{
    position: absolute;
    bottom: 79px;
    display: flex;
    flex-direction: row;
    background-color: #0E212E;
    align-items: center;
    border-radius: 28px;
    height: 48px;
    width: calc(100% - 33px);
    margin-left: 16px;
    margin-right: 17px;
    border: 2px solid #304553;
}

.searchbar-input{
    background-color: #0E212E;
    border: none;
    margin-left: 22px;
    font-family: 'Muller', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    flex: 1
}

.searchbar-image-container{
    height: 18px;
    width: 18px;
    margin-right: 15px;
}

.searchbar-image-container-cross{
    height: 14px;
    width: 14px;
    margin-top: -2px;
    margin-right: 15px;
}

.searchbar-image{
    max-height: 100%;
    max-width: 100%;
}

::placeholder {
    color: #567086;
}

input {outline:none;}
.cards-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 85vh;
    width: calc(100% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;
}



.cards-container-row{
    display: flex;
    flex-direction: row;
    gap: 6px
}

.container-card{
    width: 33%;
    max-width: 33%;
    position: relative;
    border-radius: 12px;
    aspect-ratio: 2.96 / 4;
    background-color: #0E212E;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-filter{
    position: absolute;
    width: 103%;
    aspect-ratio: 1 / 1;
    /* box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15); */
    border-radius: 12px;
    background-color: #0E212E80;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-size 0.5s ease-in-out;
}


.card-image {
    width: 104%;
    aspect-ratio: 1 / 1;
    margin-top: -2px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 105%; /* Default size */
    display: block;
    transition: background-size 0.3s ease-in-out;
}



/* Class for expanded background size */
.card-image.expanded {
    background-size: 130%;
}



.card-fs-section{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 16px;
    font-size: 15px;
    color: #FFFFFF;
}


.card-filter-text-area{
    font-family: 'Muller', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    position: absolute;
    top: 13px
}


.card-filter-image-container{
    height: 48px;
    width: 48px;
}

.card-filter-image{
    max-height: 100%;
    max-width: 100%;
}



.gradient {
    position: absolute;
    overflow: hidden;
    width: 100vw;  /* Занимает всю ширину экрана */
    height: 20px;  /* Высота полосы */
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    /* box-shadow: 0 0 50px 50px rgba(255, 255, 255, 0.9); дополнительное размытие краев */
    transform: rotate(-45deg); /* Наклоняем элемент на 45 градусов */
    animation: move-diagonal 7s infinite linear; /* Анимация */
    
    animation-delay: 1s;
    pointer-events: none;  
    filter: blur(20px);

  }
  
  /* Анимация движения по диагонали */
  @keyframes move-diagonal {
    0% {
      top: -300px;   /* Начало анимации чуть выше экрана */
      left: -1000px;  /* Начало анимации чуть слева от экрана */
    }
    100% {
      top: 100%;    /* Конец анимации внизу экрана */
      left: 100%;   /* Конец анимации справа экрана */
    }
  }

.breakable-line{
    min-height: 40px;
}
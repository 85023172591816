.empty-search{
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-search-text{
    font-family: 'Muller', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: white;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'Muller';
  src: url('./fonts/muller.ttf') format('truetype'); /* путь зависит от вашего расположения файла */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1A2C38;
}




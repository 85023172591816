.bottom-menu{
    position: fixed;
    bottom: 0;
    height: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #1A2C38;
}


.bottom-menu-item-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.bottom-menu-item-image-container{
    height: 24px;
    width: 24px;
}


.bottom-menu-item-image{
    max-width: 100%;
    max-height: 100%;
}


.bottom-menu-item-title{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    line-height: 16px;
    font-size: 12px;
    color: #E6E0E9;
    margin-top: 7.5px;
}
.promo-area{
    height: 85vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Muller', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    color: white;
}
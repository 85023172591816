.games-container{
    overflow-y: auto;
    height: 85vh;
    width: calc(100% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.games-container-row{
    display: flex;
    flex-direction: row;
    gap: 6px
}

.games-container-item{
    width: 33%;
    border-radius: 12px;
    padding-top: 43.3%;
}
